body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  overflow: hidden;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: black;
  pointer-events: none;
  transition-duration: 400ms;
}

.overlay-visible {
  opacity: 0.5;
}

.overlay-invisible {
  opacity: 0;
}